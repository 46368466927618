<template>
  <div class="search-filters d-none d-lg-flex">
    <div>
      <ZButton
        ref="drivableTrigger"
        outline
        :class="['btn-popover', { active: Boolean(drivableTypeCount) }]"
        @click="handleDrivableTriggerClick"
      >
        {{ t('searchFilters.rvType.drivable.title') }}

        <template v-if="Boolean(drivableTypeCount)">
          ({{ drivableTypeCount }})
        </template>
        <fa
          :icon="drivablePopoverEl?.isVisible ? 'chevron-up' : 'chevron-down'"
          class="ml-1"
        />
      </ZButton>
      <ZPopover
        ref="drivablePopoverEl"
        placement="bottom-start"
        css-class="drivable-popover"
      >
        <template #default="{ close }">
          <LazySearchFilterRvType
            v-slot="slotProps"
            type="drivable"
            :value="$search.parameters.filters?.drivable"
          >
            <LazySearchFormButtons
              class="mt-3"
              @apply="
                () => {
                  changed('drivable', slotProps.value)
                  close()
                }
              "
              @clear="
                () => {
                  changed('drivable', {})
                  close()
                }
              "
            />
          </LazySearchFilterRvType>
        </template>
      </ZPopover>
    </div>

    <div>
      <ZButton
        ref="towableTrigger"
        outline
        :class="['btn-popover', { active: Boolean(towableTypeCount) }]"
        @click="handleTowableTriggerClick"
      >
        {{ t('searchFilters.rvType.towable.title') }}

        <template v-if="Boolean(towableTypeCount)">
          ({{ towableTypeCount }})
        </template>
        <fa
          :icon="towablePopoverEl?.isVisible ? 'chevron-up' : 'chevron-down'"
          class="ml-1"
        />
      </ZButton>
      <ZPopover
        ref="towablePopoverEl"
        placement="bottom-start"
        css-class="towable-popover"
      >
        <template #default="{ close }">
          <LazySearchFilterRvType
            v-slot="slotProps"
            type="towable"
            :value="$search.parameters.filters?.towable"
          >
            <LazySearchFormButtons
              class="mt-3"
              @apply="
                () => {
                  changed('towable', slotProps.value)
                  close()
                }
              "
              @clear="
                () => {
                  changed('towable', {})
                  close()
                }
              "
            />
          </LazySearchFilterRvType>
        </template>
      </ZPopover>
    </div>

    <SearchQuickFilters class="d-none d-lg-block" />

    <div>
      <ZButton
        ref="moreFiltersTrigger"
        data-testid="more-filters"
        outline
        :class="['btn-popover', { active: activeFiltersCount > 0 }]"
        @click="handleMoreFiltersTriggerClick"
      >
        <fa
          class="mr-1"
          :icon="['far', 'sliders-simple']"
        />
        {{ t('searchFilters.title') }}

        <template v-if="activeFiltersCount > 0">
          ({{ activeFiltersCount }})
        </template>
        <fa
          :icon="moreFiltersPopoverEl?.isVisible ? 'chevron-up' : 'chevron-down'"
          class="ml-1"
        />
      </ZButton>
      <ZPopover
        ref="moreFiltersPopoverEl"
        placement="bottom-end"
        css-class="more-filters-popover"
      >
        <template #default="{ close }">
          <LazySearchFilterMoreFilters @close="close()" />
        </template>
      </ZPopover>
    </div>
  </div>
</template>

<script>
import { trackSearchFilterMenuOpened } from '~/lib/tracking'
import { SearchCollapsibleFilters } from '~/lib/enums/frontendEnums'

export default {
  setup() {
    const { t } = useI18n()

    const { isLargeBreakpoint } = useBreakpoint()

    const { routeBaseName } = useBaseName()

    const drivablePopoverEl = ref(null)
    const towablePopoverEl = ref(null)
    const moreFiltersPopoverEl = ref(null)

    return {
      t,
      isLargeBreakpoint,
      routeBaseName,
      drivablePopoverEl,
      towablePopoverEl,
      moreFiltersPopoverEl,
    }
  },

  computed: {
    activeFiltersCount() {
      let count = 0

      if (!this.isLargeBreakpoint) {
        if (this.$search.parameters.filters?.delivery) {
          count++
        }
      }

      if (!this.isLargeBreakpoint) {
        if (this.$search.parameters.filters?.instantBook) {
          count++
        }
      }

      if (Object.values(this.$search.parameters.filters?.rvPrice ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters?.amenities ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters?.rvWeight ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters?.rvLength ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters?.rvYear ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters?.rvBrand ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (this.$search.parameters.filters?.festivalFriendly) {
        count++
      }

      if (this.$search.parameters.filters?.petFriendly) {
        count++
      }

      if (this.$search.parameters.filters?.experienceNotRequired) {
        count++
      }

      if (this.$search.parameters.filters?.superhost) {
        count++
      }

      return count
    },

    drivableTypeCount() {
      return Object.values(this.$search.parameters.filters?.drivable ?? {}).filter((type) => type).length
    },

    towableTypeCount() {
      return Object.values(this.$search.parameters.filters?.towable ?? {}).filter((type) => type).length
    },

    drivable() {
      return SearchCollapsibleFilters.Drivable
    },

    towable() {
      return SearchCollapsibleFilters.Towable
    },

    filters() {
      return SearchCollapsibleFilters.Filters
    },
  },

  methods: {
    changed(filter, value) {
      this.$search.updateFilter(filter, value)
      this.$search.applyMenuFilter()
    },

    trackMenuOpened(menuName) {
      if (!menuName) return

      trackSearchFilterMenuOpened({
        menuName,
        pageSource: this.routeBaseName,
      })
    },

    handleDrivableTriggerClick(event) {
      this.drivablePopoverEl?.toggleShow(event.currentTarget)
      if (this.drivablePopoverEl?.isVisible) {
        this.trackMenuOpened(this.drivable)
      }
    },

    handleTowableTriggerClick(event) {
      this.towablePopoverEl?.toggleShow(event.currentTarget)
      if (this.towablePopoverEl?.isVisible) {
        this.trackMenuOpened(this.towable)
      }
    },

    handleMoreFiltersTriggerClick(event) {
      this.moreFiltersPopoverEl?.toggleShow(event.currentTarget)
      if (this.moreFiltersPopoverEl?.isVisible) {
        this.trackMenuOpened(this.filters)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(p) {
  @include caption;

  strong {
    ~ span {
      display: inline-block;
      margin-top: 0.5rem;
    }
  }

  span {
    @include regular-weight;

    color: getColor('primary-300');
  }
}
</style>

<i18n src="~/locales/common/search/filters/index.json" lang="json" />

<i18n src="~/locales/common/search/filters/rv-types.json" lang="json" />
