<template>
  <div v-if="isShowing">
    <div class="highlight-banner">
      <div class="banner">
        <div class="content">
          <span class="highlight mr-3">{{ t('highlight') }}</span>
          <template v-if="isLargeBreakpoint">
            <span class="text">{{ t('text') }}</span>
            <span class="ml-3">
              <ZButton
                size="sm"
                variant="white"
                @click="applySearchFilter"
              >{{ t('cta') }}</ZButton>
            </span>
          </template>
        </div>
        <div class="close">
          <ZClose @click="handleDismiss" />
        </div>
      </div>
      <div
        v-if="!isLargeBreakpoint"
        class="mobile-banner"
      >
        <span class="text">{{ t('text') }}</span>
        <span
          class="cta"
          :class="{ fr: $i18n.locale === 'fr' }"
        >
          <ZButton
            size="sm"
            variant="white"
            @click="applySearchFilter"
          >{{ t('cta') }}</ZButton>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { COOKIES } from '~/constants'
import { THIRTY_DAYS_IN_SECONDS } from '~/constants/durations'

const { $search } = useNuxtApp()
const { isDesktop } = useDevice()
const { isWebView } = usePlatform()
const { isInUs } = useGeolocation()
const { isLargeBreakpoint } = useBreakpoint()
const { t } = useI18n()
const { trackBannerClicked } = useTracking()
const { getOs } = useOs()
const { routeBaseName } = useBaseName()

const VERSION = 2

const cookieName = computed(() => `${COOKIES.highlightBanner}-${VERSION}`)
const bannerCookie = useCookie(cookieName.value, {
  default: () => '0',
  maxAge: THIRTY_DAYS_IN_SECONDS,
})
const isShowing = computed(() => !Number(bannerCookie.value) && !isWebView.value)

function handleDismiss() {
  bannerCookie.value = '1'
}

function trackBanner() {
  trackBannerClicked({
    deviceType: isDesktop ? 'desktop' : 'mobile',
    os: getOs(),
    userCountry: isInUs.value ? 'US' : 'CA',
    bannerName: 'thanksgiving',
    pageSource: routeBaseName.value,
  })
}

function applySearchFilter() {
  const result = selectThanksgivingDates(isInUs.value)
  $search.updateDates({ start: result.start, end: result.end })
  handleDismiss()
  trackBanner()
}
</script>

<style lang="scss" scoped>
.close {
  margin-left: auto;
  .zcircle-button {
    transform: scale(0.75);
  }
}

.highlight-banner {
  background-color: getColor('primary-500');
  color: #fff;
  padding: 1rem 1.5rem;

  @include media-max-size(large) {
    margin: 1.5rem 1rem 0 1rem;
    border-radius: 1rem;
  }

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-max-size(large) {
      align-items: start;
    }

    .content {
      span.highlight {
        font-weight: 600;
        color: #ffd166;

        @include subtitle-1;

        @include media-max-size(large) {
          @include strong-1;
        }
      }
      span.text {
        @include body-1;
      }
    }
  }
  .mobile-banner {
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span.text {
      margin-right: 0.5rem;
      @include caption;
    }

    span.cta {
      min-width: 6.25rem;
      &.fr {
        min-width: 7.7rem;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
      "highlight": "Celebrate Thanksgiving! 🍁",
      "text": "Find your perfect RV for a festive fall stay.",
      "cta": "View RVs"
    },
  "fr": {
      "highlight": "Célébrez l’action de grâce ! 🍁",
      "text": "Trouvez votre VR parfait pour un séjour automnal festif.",
      "cta": "Voir des VRs"
    }
}
</i18n>
