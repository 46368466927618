export default function () {
  const { isAndroid, isIos, isWindows, isMacOS } = useDevice()

  const getOs = () => {
    if (isAndroid) return 'android'
    if (isIos) return 'ios'
    if (isMacOS) return 'macos'
    if (isWindows) return 'windows'
    return 'other'
  }

  return { getOs }
}
