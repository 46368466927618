<template>
  <div class="map-wrapper">
    <div class="map-sticky">
      <ClientOnly>
        <SearchMapGoogle
          v-bind="mapProps"
          class="position-relative"
        />
        <ZOverlay
          :show="isResultsLoading"
          no-wrap
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script>
import clone from 'clone'

export default {
  inheritAttrs: false,

  props: {
    shouldMount: {
      type: Boolean,
      default: false,
    },

    isResultsLoading: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },

    hoveredId: {
      type: String,
      default: null,
    },

    showZoomControls: {
      type: Boolean,
      default: true,
    },

    showSearchOnMove: {
      type: Boolean,
      default: true,
    },

    updateSearchOnMapMove: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    searchLocation() {
      return clone(this.$search.parameters.location)
    },

    boundingBox() {
      if (this.$search.parameters.bounds.hasBounds) {
        return {
          north: this.$search.parameters.bounds.north,
          east: this.$search.parameters.bounds.east,
          south: this.$search.parameters.bounds.south,
          west: this.$search.parameters.bounds.west,
          searched: true,
        }
      }
      else if (this.searchLocation.bounds) {
        return this.searchLocation.bounds
      }

      return null
    },

    mapProps() {
      return {
        hoveredId: this.hoveredId,
        centerPoint: this.searchLocation?.center,
        boundingBox: this.boundingBox,
        items: this.items,
        showZoomControls: this.showZoomControls,
        showSearchOnMove: this.showSearchOnMove,
        updateSearchOnMapMove: this.updateSearchOnMapMove,
        shouldMount: this.shouldMount,
        ...this.$attrs,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper {
  background-color: getColor('gray-75');

  @include media-min-size(large) {
    position: relative;

    .map-sticky {
      position: sticky;
      top: 9.4375rem;
    }
  }

  :deep(.map) {
    position: relative;
    width: 100%;
    height: calc(100vh - 11.9375rem);
    height: calc(100svh - 11.9375rem);

    @include media-min-size(medium) {
      height: calc(100vh - 8.6875rem);
      height: calc(100svh - 8.6875rem);
    }

    @include media-min-size(large) {
      height: calc(100vh - 9.4375rem);
      height: calc(100svh - 9.4375rem);
    }
  }
}
</style>
